/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StockOrderDetails_stockOrder$ref = any;
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type FeeType = "ADE" | "ADF" | "ASB" | "AVH" | "BUF" | "CHI" | "CHIP" | "CHUP" | "CON" | "COR" | "CRDT" | "FXB" | "GST" | "INT" | "MGN" | "MSB" | "PLF" | "PLT" | "PRO" | "RTA" | "RTP" | "SIPP" | "STB" | "STBV" | "TAX" | "TRA" | "VHF" | "WDR" | "%future added value";
export type OrderStatus = "CAN" | "CRE" | "FIL" | "INI" | "INM" | "PEX" | "PFL" | "POS" | "QUE" | "REJ" | "%future added value";
export type OrderType = "LMT" | "MKT" | "SLMT" | "SMKT" | "TLMT" | "TMKT" | "%future added value";
export type PortfolioOrderSide = "BUY" | "REB" | "SELL" | "%future added value";
export type PortfolioOrderStatus = "CAN" | "COM" | "CRE" | "FIL" | "PEX" | "PFL" | "PRO" | "REJ" | "%future added value";
export type ReportState = "ERR" | "FIN" | "REQ" | "%future added value";
export type Side = "BUY" | "SELL" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BulkOrderView_bulkOrder$ref: FragmentReference;
declare export opaque type BulkOrderView_bulkOrder$fragmentType: BulkOrderView_bulkOrder$ref;
export type BulkOrderView_bulkOrder = {|
  +id: string,
  +createdBy: ?{|
    +id: string,
    +fullName: ?string,
  |},
  +portfolioorderSet: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +side: PortfolioOrderSide,
        +createdAt: any,
        +updatedAt: any,
        +status: PortfolioOrderStatus,
        +bulkOrder: ?{|
          +id: string
        |},
        +portfolio: {|
          +id: string,
          +trackingVueName: ?string,
          +trackingVue: ?{|
            +id: string,
            +name: string,
          |},
        |},
        +account: {|
          +id: string,
          +name: ?string,
        |},
        +tradeInvoice: ?{|
          +pdf: ?string,
          +pdfUrl: ?string,
          +createdAt: any,
          +updatedAt: any,
          +state: ReportState,
        |},
        +stockOrders: {|
          +edges: $ReadOnlyArray<?{|
            +node: ?{|
              +$fragmentRefs: StockOrderDetails_stockOrder$ref
            |}
          |}>
        |},
      |}
    |}>
  |},
  +fxorderSet: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +price: ?number,
        +quantity: ?number,
        +createdAt: any,
        +updatedAt: any,
        +quantityToDate: ?number,
        +contract: {|
          +id: string,
          +currency: Currency,
          +symbol: string,
          +localSymbol: string,
          +ibConId: number,
        |},
        +orderPtr: {|
          +account: {|
            +id: string,
            +name: ?string,
          |},
          +orderType: OrderType,
          +executionSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +transaction: ?{|
                  +id: string
                |},
                +price: number,
                +proceeds: ?number,
                +executionDatetime: any,
                +quantity: number,
                +currency: Currency,
              |}
            |}>
          |},
          +transactions: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +commission: ?number,
                +tax: ?number,
                +transactionaccountchargeSet: {|
                  +edges: $ReadOnlyArray<?{|
                    +node: ?{|
                      +id: string,
                      +amount: number,
                      +currency: Currency,
                      +feeType: FeeType,
                      +accountchargePtr: {|
                        +id: string,
                        +amount: number,
                        +currency: Currency,
                        +accountchargetenantportionSet: {|
                          +edges: $ReadOnlyArray<?{|
                            +node: ?{|
                              +id: string,
                              +quantity: number,
                              +tenant: ?{|
                                +name: string,
                                +id: string,
                              |},
                            |}
                          |}>
                        |},
                      |},
                    |}
                  |}>
                |},
              |}
            |}>
          |},
        |},
        +brokerOrder: ?{|
          +id: string,
          +twsOrderId: ?number,
          +twsPermId: ?number,
          +cashQuantity: ?number,
          +quantityFilled: ?number,
          +quantityLeft: ?number,
          +stopPrice: ?number,
          +trailStopRatio: ?number,
          +trailStopOffset: ?number,
          +lastSyncTime: ?any,
          +status: OrderStatus,
          +orderRef: ?string,
          +side: Side,
          +orderType: OrderType,
          +ibExchange: ?{|
            +id: string,
            +symbol: string,
          |},
          +brokerorderamendmentSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +quantity: number,
                +previousQuantity: number,
                +previousLimitPrice: ?number,
                +limitPrice: ?number,
                +amendmentRef: ?string,
              |}
            |}>
          |},
        |},
      |}
    |}>
  |},
  +$refType: BulkOrderView_bulkOrder$ref,
|};
export type BulkOrderView_bulkOrder$data = BulkOrderView_bulkOrder;
export type BulkOrderView_bulkOrder$key = {
  +$data?: BulkOrderView_bulkOrder$data,
  +$fragmentRefs: BulkOrderView_bulkOrder$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "side",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v5 = [
  (v0/*: any*/)
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v7 = [
  (v0/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "account",
  "storageKey": null,
  "args": null,
  "concreteType": "AccountNode",
  "plural": false,
  "selections": (v7/*: any*/)
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currency",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "symbol",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderType",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "BulkOrderView_bulkOrder",
  "type": "BulkOrderNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "createdBy",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "portfolioorderSet",
      "storageKey": null,
      "args": null,
      "concreteType": "PortfolioOrderNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "PortfolioOrderNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "PortfolioOrderNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "bulkOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BulkOrderNode",
                  "plural": false,
                  "selections": (v5/*: any*/)
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "portfolio",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "PortfolioNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "trackingVueName",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "trackingVue",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "VueNode",
                      "plural": false,
                      "selections": (v7/*: any*/)
                    }
                  ]
                },
                (v8/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tradeInvoice",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TradeInvoiceNode",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "pdf",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "pdfUrl",
                      "args": null,
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    (v3/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "state",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "stockOrders",
                  "storageKey": "stockOrders(orderBy:\"createdAt\")",
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "orderBy",
                      "value": "createdAt"
                    }
                  ],
                  "concreteType": "StockOrderNodeConnection",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "edges",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "StockOrderNodeEdge",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "node",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "StockOrderNode",
                          "plural": false,
                          "selections": [
                            {
                              "kind": "FragmentSpread",
                              "name": "StockOrderDetails_stockOrder",
                              "args": null
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "fxorderSet",
      "storageKey": null,
      "args": null,
      "concreteType": "FxOrderNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "FxOrderNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "FxOrderNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "quantityToDate",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "contract",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "FxContractNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v11/*: any*/),
                    (v12/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "localSymbol",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "ibConId",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "orderPtr",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "OrderNode",
                  "plural": false,
                  "selections": [
                    (v8/*: any*/),
                    (v13/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "executionSet",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "ExecutionNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "ExecutionNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "ExecutionNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "transaction",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "TransactionNode",
                                  "plural": false,
                                  "selections": (v5/*: any*/)
                                },
                                (v9/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "proceeds",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "executionDatetime",
                                  "args": null,
                                  "storageKey": null
                                },
                                (v10/*: any*/),
                                (v11/*: any*/)
                              ]
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "transactions",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "TransactionNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "TransactionNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "TransactionNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "commission",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "tax",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "LinkedField",
                                  "alias": null,
                                  "name": "transactionaccountchargeSet",
                                  "storageKey": null,
                                  "args": null,
                                  "concreteType": "TransactionAccountChargeNodeConnection",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "LinkedField",
                                      "alias": null,
                                      "name": "edges",
                                      "storageKey": null,
                                      "args": null,
                                      "concreteType": "TransactionAccountChargeNodeEdge",
                                      "plural": true,
                                      "selections": [
                                        {
                                          "kind": "LinkedField",
                                          "alias": null,
                                          "name": "node",
                                          "storageKey": null,
                                          "args": null,
                                          "concreteType": "TransactionAccountChargeNode",
                                          "plural": false,
                                          "selections": [
                                            (v0/*: any*/),
                                            (v14/*: any*/),
                                            (v11/*: any*/),
                                            {
                                              "kind": "ScalarField",
                                              "alias": null,
                                              "name": "feeType",
                                              "args": null,
                                              "storageKey": null
                                            },
                                            {
                                              "kind": "LinkedField",
                                              "alias": null,
                                              "name": "accountchargePtr",
                                              "storageKey": null,
                                              "args": null,
                                              "concreteType": "AccountChargeNode",
                                              "plural": false,
                                              "selections": [
                                                (v0/*: any*/),
                                                (v14/*: any*/),
                                                (v11/*: any*/),
                                                {
                                                  "kind": "LinkedField",
                                                  "alias": null,
                                                  "name": "accountchargetenantportionSet",
                                                  "storageKey": null,
                                                  "args": null,
                                                  "concreteType": "AccountChargeTenantPortionNodeConnection",
                                                  "plural": false,
                                                  "selections": [
                                                    {
                                                      "kind": "LinkedField",
                                                      "alias": null,
                                                      "name": "edges",
                                                      "storageKey": null,
                                                      "args": null,
                                                      "concreteType": "AccountChargeTenantPortionNodeEdge",
                                                      "plural": true,
                                                      "selections": [
                                                        {
                                                          "kind": "LinkedField",
                                                          "alias": null,
                                                          "name": "node",
                                                          "storageKey": null,
                                                          "args": null,
                                                          "concreteType": "AccountChargeTenantPortionNode",
                                                          "plural": false,
                                                          "selections": [
                                                            (v0/*: any*/),
                                                            (v10/*: any*/),
                                                            {
                                                              "kind": "LinkedField",
                                                              "alias": null,
                                                              "name": "tenant",
                                                              "storageKey": null,
                                                              "args": null,
                                                              "concreteType": "TenantNode",
                                                              "plural": false,
                                                              "selections": [
                                                                (v6/*: any*/),
                                                                (v0/*: any*/)
                                                              ]
                                                            }
                                                          ]
                                                        }
                                                      ]
                                                    }
                                                  ]
                                                }
                                              ]
                                            }
                                          ]
                                        }
                                      ]
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "brokerOrder",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "BrokerOrderNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "twsOrderId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "twsPermId",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "cashQuantity",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "quantityFilled",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "quantityLeft",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "stopPrice",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "trailStopRatio",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "trailStopOffset",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "lastSyncTime",
                      "args": null,
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "orderRef",
                      "args": null,
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v13/*: any*/),
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "ibExchange",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "IBExchangeNode",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v12/*: any*/)
                      ]
                    },
                    {
                      "kind": "LinkedField",
                      "alias": null,
                      "name": "brokerorderamendmentSet",
                      "storageKey": null,
                      "args": null,
                      "concreteType": "BrokerOrderAmendmentNodeConnection",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "LinkedField",
                          "alias": null,
                          "name": "edges",
                          "storageKey": null,
                          "args": null,
                          "concreteType": "BrokerOrderAmendmentNodeEdge",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "LinkedField",
                              "alias": null,
                              "name": "node",
                              "storageKey": null,
                              "args": null,
                              "concreteType": "BrokerOrderAmendmentNode",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v10/*: any*/),
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "previousQuantity",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "previousLimitPrice",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "limitPrice",
                                  "args": null,
                                  "storageKey": null
                                },
                                {
                                  "kind": "ScalarField",
                                  "alias": null,
                                  "name": "amendmentRef",
                                  "args": null,
                                  "storageKey": null
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '8a3a1cbd7e52c41efedf3b8d73f2337c';
module.exports = node;
