/**
 * @flow
 * @relayHash 1f5cb47e0e1b23178c5c03b53411c976
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BankAccountVerification_bankAccount$ref = any;
export type BankAccountUploadDocumentMutationVariables = {|
  bankAccountId?: ?string,
  files?: ?$ReadOnlyArray<?any>,
|};
export type BankAccountUploadDocumentMutationResponse = {|
  +uploadBankAccountDocument: ?{|
    +bankAccount: ?{|
      +$fragmentRefs: BankAccountVerification_bankAccount$ref
    |}
  |}
|};
export type BankAccountUploadDocumentMutation = {|
  variables: BankAccountUploadDocumentMutationVariables,
  response: BankAccountUploadDocumentMutationResponse,
|};
*/


/*
mutation BankAccountUploadDocumentMutation(
  $bankAccountId: ID
  $files: [Upload]
) {
  uploadBankAccountDocument(bankAccountId: $bankAccountId, files: $files) {
    bankAccount {
      ...BankAccountVerification_bankAccount
      id
    }
  }
}

fragment BankAccountVerification_bankAccount on BankAccountNode {
  id
  holderName
  bsbNumber
  accountNumber
  createdAt
  updatedAt
  status
  dividendPayout
  account {
    id
    name
  }
  bankaccountdocumentSet {
    edges {
      node {
        id
        file
        fileName
        fileUrl
        updatedAt
        uploadedBy {
          fullName
          id
        }
      }
    }
  }
  approvals {
    edges {
      node {
        id
        approvedBy {
          id
          username
        }
        approvedAt
      }
    }
  }
  hasCompleteApprovals
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "bankAccountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "files",
    "type": "[Upload]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "bankAccountId",
    "variableName": "bankAccountId"
  },
  {
    "kind": "Variable",
    "name": "files",
    "variableName": "files"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BankAccountUploadDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "uploadBankAccountDocument",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadBankAccountDocumentationAdmin",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bankAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "BankAccountNode",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "BankAccountVerification_bankAccount",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BankAccountUploadDocumentMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "uploadBankAccountDocument",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadBankAccountDocumentationAdmin",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bankAccount",
            "storageKey": null,
            "args": null,
            "concreteType": "BankAccountNode",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "holderName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "bsbNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "accountNumber",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "createdAt",
                "args": null,
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "dividendPayout",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "account",
                "storageKey": null,
                "args": null,
                "concreteType": "AccountNode",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "bankaccountdocumentSet",
                "storageKey": null,
                "args": null,
                "concreteType": "BankAccountDocumentNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BankAccountDocumentNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BankAccountDocumentNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "file",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fileName",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "fileUrl",
                            "args": null,
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "uploadedBy",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "fullName",
                                "args": null,
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "approvals",
                "storageKey": null,
                "args": null,
                "concreteType": "ApprovalNodeConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "edges",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ApprovalNodeEdge",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "node",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ApprovalNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "approvedBy",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserNode",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "username",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "approvedAt",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasCompleteApprovals",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "mutation",
    "name": "BankAccountUploadDocumentMutation",
    "id": null,
    "text": "mutation BankAccountUploadDocumentMutation(\n  $bankAccountId: ID\n  $files: [Upload]\n) {\n  uploadBankAccountDocument(bankAccountId: $bankAccountId, files: $files) {\n    bankAccount {\n      ...BankAccountVerification_bankAccount\n      id\n    }\n  }\n}\n\nfragment BankAccountVerification_bankAccount on BankAccountNode {\n  id\n  holderName\n  bsbNumber\n  accountNumber\n  createdAt\n  updatedAt\n  status\n  dividendPayout\n  account {\n    id\n    name\n  }\n  bankaccountdocumentSet {\n    edges {\n      node {\n        id\n        file\n        fileName\n        fileUrl\n        updatedAt\n        uploadedBy {\n          fullName\n          id\n        }\n      }\n    }\n  }\n  approvals {\n    edges {\n      node {\n        id\n        approvedBy {\n          id\n          username\n        }\n        approvedAt\n      }\n    }\n  }\n  hasCompleteApprovals\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8f70f8ff37081457caf5142ff282d5f9';
module.exports = node;
