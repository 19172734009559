/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type OrderApprovalStatus = "CA" | "CD" | "EX" | "IN" | "OA" | "OD" | "RR" | "SA" | "SFA" | "%future added value";
export type OrderProposalType = "BT" | "IN" | "PR" | "%future added value";
export type PortfolioOrderStatus = "CAN" | "COM" | "CRE" | "FIL" | "PEX" | "PFL" | "PRO" | "REJ" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ApprovalDetailVerticalTable_approvalDetails$ref: FragmentReference;
declare export opaque type ApprovalDetailVerticalTable_approvalDetails$fragmentType: ApprovalDetailVerticalTable_approvalDetails$ref;
export type ApprovalDetailVerticalTable_approvalDetails = {|
  +id: string,
  +portfolioOrder: ?{|
    +id: string,
    +status: PortfolioOrderStatus,
  |},
  +createdAt: any,
  +updatedAt: any,
  +approvedAt: ?any,
  +notifiedAt: ?any,
  +remindedAt: ?any,
  +proposal: {|
    +id: string,
    +type: OrderProposalType,
  |},
  +rationaleUrl: ?string,
  +approvedBy: ?{|
    +fullName: ?string
  |},
  +status: OrderApprovalStatus,
  +portfolio: {|
    +id: string,
    +trackingVueName: ?string,
    +account: {|
      +name: ?string,
      +id: string,
    |},
  |},
  +$refType: ApprovalDetailVerticalTable_approvalDetails$ref,
|};
export type ApprovalDetailVerticalTable_approvalDetails$data = ApprovalDetailVerticalTable_approvalDetails;
export type ApprovalDetailVerticalTable_approvalDetails$key = {
  +$data?: ApprovalDetailVerticalTable_approvalDetails$data,
  +$fragmentRefs: ApprovalDetailVerticalTable_approvalDetails$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ApprovalDetailVerticalTable_approvalDetails",
  "type": "OrderApprovalNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "portfolioOrder",
      "storageKey": null,
      "args": null,
      "concreteType": "PortfolioOrderNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "createdAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "updatedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "approvedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "notifiedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "remindedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "proposal",
      "storageKey": null,
      "args": null,
      "concreteType": "OrderProposalNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "type",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rationaleUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "approvedBy",
      "storageKey": null,
      "args": null,
      "concreteType": "UserNode",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "fullName",
          "args": null,
          "storageKey": null
        }
      ]
    },
    (v1/*: any*/),
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "portfolio",
      "storageKey": null,
      "args": null,
      "concreteType": "PortfolioNode",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "trackingVueName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "account",
          "storageKey": null,
          "args": null,
          "concreteType": "AccountNode",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            (v0/*: any*/)
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b5d0508bed2ebd3f2c717aeb8697332e';
module.exports = node;
