import React from 'react';
import Grid from '@material-ui/core/Grid';
import TableStatus from '../common/TableStatus';
import EnumValue from '../common/EnumValue';
import { COLOR_LIGHT_GREEN } from '../../common/constants';

const bankAccountColor = {
  VER: { color: 'primary' },
  PEN: {},
  DIS: { color: 'secondary' },
};

function BankAccountStatus(props) {
  const { bankAccount } = props;
  return (
    <Grid container justify="flex-end" spacing={1}>
      <Grid item>
        <TableStatus {...bankAccountColor[bankAccount.status]}>
          <EnumValue
            enumType="BankAccountStatus"
            enumValue={bankAccount.status}
          />
        </TableStatus>
      </Grid>
      {bankAccount.dividendPayout && (
        <Grid item>
          <TableStatus customStyle={{ backgroundColor: COLOR_LIGHT_GREEN }}>
            D.P.
          </TableStatus>
        </Grid>
      )
      }
    </Grid>
  );
}

export default BankAccountStatus;
