/**
 * @flow
 * @relayHash bfdb7babe5ba265ae18f7cd06d0c08c9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type BankAccountList_viewer$ref = any;
export type BankAccountListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  status?: ?string,
  docsUploaded?: ?boolean,
  search?: ?string,
|};
export type BankAccountListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: BankAccountList_viewer$ref
  |}
|};
export type BankAccountListQuery = {|
  variables: BankAccountListQueryVariables,
  response: BankAccountListQueryResponse,
|};
*/


/*
query BankAccountListQuery(
  $first: Int
  $offset: Int
  $status: String
  $docsUploaded: Boolean
  $search: String
) {
  viewer {
    ...BankAccountList_viewer_3ZmGus
    id
  }
}

fragment BankAccountList_viewer_3ZmGus on Query {
  bankAccounts(first: $first, offset: $offset, status: $status, docsUploaded: $docsUploaded, search: $search, orderBy: "-id") {
    count
    edges {
      node {
        id
        account {
          id
          name
        }
        notes {
          id
        }
        createdAt
        bsbNumber
        accountNumber
        holderName
        status
        dividendPayout
        bankaccountdocumentSet {
          count
          edges {
            node {
              id
              fileUrl
              fileName
            }
          }
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "status",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "docsUploaded",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  }
],
v1 = {
  "kind": "Variable",
  "name": "docsUploaded",
  "variableName": "docsUploaded"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "offset",
  "variableName": "offset"
},
v4 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v5 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "count",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BankAccountListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "BankAccountList_viewer",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BankAccountListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bankAccounts",
            "storageKey": null,
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "-id"
              },
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "concreteType": "BankAccountNodeConnection",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BankAccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BankAccountNode",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "account",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AccountNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "name",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "notes",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "NotesNode",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "createdAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "bsbNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "accountNumber",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "holderName",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dividendPayout",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bankaccountdocumentSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BankAccountDocumentNodeConnection",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BankAccountDocumentNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BankAccountDocumentNode",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "fileUrl",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "fileName",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BankAccountListQuery",
    "id": null,
    "text": "query BankAccountListQuery(\n  $first: Int\n  $offset: Int\n  $status: String\n  $docsUploaded: Boolean\n  $search: String\n) {\n  viewer {\n    ...BankAccountList_viewer_3ZmGus\n    id\n  }\n}\n\nfragment BankAccountList_viewer_3ZmGus on Query {\n  bankAccounts(first: $first, offset: $offset, status: $status, docsUploaded: $docsUploaded, search: $search, orderBy: \"-id\") {\n    count\n    edges {\n      node {\n        id\n        account {\n          id\n          name\n        }\n        notes {\n          id\n        }\n        createdAt\n        bsbNumber\n        accountNumber\n        holderName\n        status\n        dividendPayout\n        bankaccountdocumentSet {\n          count\n          edges {\n            node {\n              id\n              fileUrl\n              fileName\n            }\n          }\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2175582ac385d878ef5071628fc0a6ef';
module.exports = node;
