/**
 * @flow
 * @relayHash ea23ea8b93f0076fc40904035228087c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeeType = "ADE" | "ADF" | "ASB" | "AVH" | "BUF" | "CHI" | "CHIP" | "CHUP" | "CON" | "COR" | "CRDT" | "FXB" | "GST" | "INT" | "MGN" | "MSB" | "PLF" | "PLT" | "PRO" | "RTA" | "RTP" | "SIPP" | "STB" | "STBV" | "TAX" | "TRA" | "VHF" | "WDR" | "%future added value";
export type TenantChargeInputType = {|
  tenantAccountId?: ?string,
  amount?: ?number,
|};
export type CreateManualAccountChargeMutationVariables = {|
  clientAccountId: string,
  description: string,
  tenantCharges: $ReadOnlyArray<?TenantChargeInputType>,
  feeType: FeeType,
  currency: string,
|};
export type CreateManualAccountChargeMutationResponse = {|
  +createManualAccountCharge: ?{|
    +manualAccountCharge: ?{|
      +id: string,
      +amount: number,
      +description: ?string,
      +createdAt: any,
      +updatedAt: any,
      +feeType: FeeType,
      +account: {|
        +id: string,
        +name: ?string,
      |},
    |}
  |}
|};
export type CreateManualAccountChargeMutation = {|
  variables: CreateManualAccountChargeMutationVariables,
  response: CreateManualAccountChargeMutationResponse,
|};
*/


/*
mutation CreateManualAccountChargeMutation(
  $clientAccountId: ID!
  $description: String!
  $tenantCharges: [TenantChargeInputType]!
  $feeType: FeeType!
  $currency: String!
) {
  createManualAccountCharge(clientAccountId: $clientAccountId, description: $description, tenantCharges: $tenantCharges, feeType: $feeType, currency: $currency) {
    manualAccountCharge {
      id
      amount
      description
      createdAt
      updatedAt
      feeType
      account {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "clientAccountId",
    "type": "ID!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "description",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "tenantCharges",
    "type": "[TenantChargeInputType]!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "feeType",
    "type": "FeeType!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "currency",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createManualAccountCharge",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "clientAccountId",
        "variableName": "clientAccountId"
      },
      {
        "kind": "Variable",
        "name": "currency",
        "variableName": "currency"
      },
      {
        "kind": "Variable",
        "name": "description",
        "variableName": "description"
      },
      {
        "kind": "Variable",
        "name": "feeType",
        "variableName": "feeType"
      },
      {
        "kind": "Variable",
        "name": "tenantCharges",
        "variableName": "tenantCharges"
      }
    ],
    "concreteType": "CreateManualAccountCharge",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "manualAccountCharge",
        "storageKey": null,
        "args": null,
        "concreteType": "ManualAccountChargeNode",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "amount",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "description",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "updatedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "feeType",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "account",
            "storageKey": null,
            "args": null,
            "concreteType": "AccountNode",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "name",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "CreateManualAccountChargeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateManualAccountChargeMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v2/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "CreateManualAccountChargeMutation",
    "id": null,
    "text": "mutation CreateManualAccountChargeMutation(\n  $clientAccountId: ID!\n  $description: String!\n  $tenantCharges: [TenantChargeInputType]!\n  $feeType: FeeType!\n  $currency: String!\n) {\n  createManualAccountCharge(clientAccountId: $clientAccountId, description: $description, tenantCharges: $tenantCharges, feeType: $feeType, currency: $currency) {\n    manualAccountCharge {\n      id\n      amount\n      description\n      createdAt\n      updatedAt\n      feeType\n      account {\n        id\n        name\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7225142d5c0c49c81ddaa1f0131ca7a7';
module.exports = node;
