import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { Drawer } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import moment from 'moment';
import MoreButton from '../../common/buttons/MoreButton';
import { MOMENT_TIME_FORMAT } from '../../../common/constants';
import { createQueryRendererProgress } from '../../common/QueryRendererProgress';
import DrawerGridItemContainer from '../../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import { useCommonStyles } from '../../common/Styles';
import VerticalTable from '../../common/VerticalTable';
import TableFieldMeta from '../../../common/TableFieldMeta';
import FileUpload from '../../common/FileUpload';
import DrawerGridItemSubGroupHeading from '../../common/drawer/DrawerGridItemSubGroupHeading';
import DrawerGridItemBottomButtonContainer from '../../common/drawer/DrawerGridItemBottomButtonContainer';
import { ApproveButton, CloseButton } from '../../common/buttons/BaseButton';
import { doCommitPromise } from '../../../common/commit';
import {
  uploadBankAccountDocument,
  updateBankAccountStatus, updateBankAccountDividendPayout,
} from '../../../mutations/BankAccount';
import createApproval from '../../../mutations/CreateApproval';
import AttachmentList from './AttachmentList';
import { makeUploadsTopArray } from '../../../common/helpers';
import BankAccountStatus from '../../globals/BankAccountStatus';
import ApprovalList from '../../approvals/ApprovalList';
import TableButton from '../../common/buttons/TableButton';
import CreditSenseStatus from '../../globals/CreditSenseStatus';

const useStyles = makeStyles(theme => ({
  CreditSenseWarningContainer: {
    display: 'flex', 
    alignItems: 'center', 
    gap: '3px',
    float: 'right',
  },
  CreditSenseWarningIcon: {
    color: 'red', 
    fontSize: 16,
  },
}));

function BankAccountVerificationData(props) {
  const { bankAccount } = props;
  const csClasses = useStyles();
  const [files, setFiles] = React.useState([]);


  const deploymentColumns = [];
  if (process.env.DEPLOYMENT_CODE === 'AU') {
    deploymentColumns.push(...[
      ['Credit Sense Verification', row => <CreditSenseStatus creditSense={row.creditsenseverification} />],
      ['Credit Sense Bank Account', row => (
        <>{(row.creditsenseverification && row.creditsenseverification.accountName) || ' '}</>
      )],
      ['Date Verified', (row) => {
        if (row.creditsenseverification && row.creditsenseverification.verifiedAt) {
          const { verifiedAt } = row.creditsenseverification;
          return moment(verifiedAt).utc().format(MOMENT_TIME_FORMAT);
        }
        return ' ';
      }],
      ['Warning', (row) => {
        if (!row.creditsenseverification) return ' ';
        if (row.creditsenseverification.status === 'PEN' || row.creditsenseverification.status === 'VER') {
          return row.creditsenseverification.note || ' ';
        }
        return (
          <div className={csClasses.CreditSenseWarningContainer}>
            <ErrorOutlineOutlinedIcon
              className={csClasses.CreditSenseWarningIcon}
            />
            <div style={{ width: 'max-content' }}>
              {row.creditsenseverification.note}
            </div>
          </div>
        );
      }]]);
  }

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Bank Account"
        />

        <Grid item xs={12}>
          <VerticalTable
            columnMeta={[
              ['ID', TableFieldMeta.localId.accessor],
              ['Holder Name', 'holderName'],
              ['BSB', 'bsbNumber'],
              ['Acc No', 'accountNumber'],
              ['Status', node => <BankAccountStatus bankAccount={node} />],
              ['Dividend Payout', node => (
                <Grid container justifyContent="flex-end" spacing={1} alignItems="baseline">
                  <Grid item>
                    <TableButton
                      disabled={node.status !== 'VER'}
                      color={node.dividendPayout ? 'secondary' : 'primary'}
                      onClickPromise={() => doCommitPromise(
                        updateBankAccountDividendPayout,
                        {
                          bankAccountId: bankAccount.id,
                          dividendPayout: !node.dividendPayout,
                        },
                        () => null,
                        () => null,
                      )}
                    >
                      { node.dividendPayout ? 'Disable' : 'Set'}
                    </TableButton>
                  </Grid>
                </Grid>
              )],
              ['Created At', TableFieldMeta.createdAt.accessor],
              ...deploymentColumns,
            ]}
            dataNode={bankAccount}
            showHeadings={false}
          />
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <CloseButton
              buttonText="Disable"
              color="secondary"
              disabled={bankAccount.status === 'DIS'}
              onClickPromise={() => doCommitPromise(
                updateBankAccountStatus,
                {
                  bankAccountId: bankAccount.id,
                  status: 'DIS',
                },
                null,
              )}
            />
          </Grid>

          <Grid item>
            <ApproveButton
              buttonText="Verify"
              disabled={bankAccount.status === 'VER' || !bankAccount.hasCompleteApprovals}
              onClickPromise={() => doCommitPromise(
                updateBankAccountStatus,
                {
                  bankAccountId: bankAccount.id,
                  status: 'VER',
                },
                null,
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

        <Grid item xs={12}>
          <ApprovalList approvals={bankAccount.approvals.edges} />
        </Grid>
        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <ApproveButton
              buttonText="Approve"
              disabled={bankAccount.status === 'VER'}
              onClickPromise={() => doCommitPromise(
                createApproval,
                {
                  approvableId: bankAccount.id,
                },
                null,
                null,
                (store) => {
                  const payload = store.getRootField('createApproval');
                  if (!payload) return;
                  const cachedBankAccount = store.get(bankAccount.id);
                  const approvals = cachedBankAccount.getLinkedRecord('approvals');
                  const approvalEdges = approvals.getLinkedRecords('edges');
                  const newApproval = payload.getLinkedRecord('approval');
                  const newApprovalEdge = store.create('newApproval', 'ApprovalNodeEdge');
                  const newHasCompleteApprovals = payload.getValue('hasCompleteApprovals');
                  newApprovalEdge.setLinkedRecord(newApproval, 'node');
                  approvals.setLinkedRecords([...approvalEdges, newApprovalEdge], 'edges');
                  cachedBankAccount.setValue(newHasCompleteApprovals, 'hasCompleteApprovals');
                },
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
 
        <DrawerGridItemSubGroupHeading heading="Verification Documents" />

        <Grid item xs={12}>
          <AttachmentList
            attachments={bankAccount.bankaccountdocumentSet.edges}
          />
        </Grid>

        <Grid item xs={12}>
          <FileUpload
            onChange={(filesIn) => {
              setFiles([...files, ...filesIn]);
            }}
            files={files}
            displayList={files.length > 0}
            onUploadPromise={() => doCommitPromise(
              uploadBankAccountDocument,
              {
                bankAccountId: bankAccount.id,
                files: files.map(x => null),
              },
              () => {
                setFiles([]);
              },
              () => null,
              null,
              makeUploadsTopArray(files),
            )}
          />
        </Grid>

      </DrawerGridItemContainer>
    </>
  );
}


const query = graphql`
  query BankAccountVerificationQuery(
    $bankAccountId: ID
  ) {
    viewer {
      bankAccounts(
        id: $bankAccountId
      ) {
        edges {
          node {
            ...BankAccountVerification_bankAccount
            @relay(mask: false)
          }
        }
      }
    }
  }
`;

const container = createFragmentContainer(
  BankAccountVerificationData,
  {
    bankAccount: graphql`
      fragment BankAccountVerification_bankAccount on BankAccountNode {
        id
        holderName
        bsbNumber
        accountNumber
        createdAt
        updatedAt
        status
        dividendPayout
        account {
          id 
          name
        }
        ...BankAccountDeploymentSpecifics @relay(mask: false)
        bankaccountdocumentSet {
          edges {
            node {
              id
              file
              fileName
              fileUrl
              updatedAt
              uploadedBy {
                fullName
              }
            }
          }
        }
        approvals {
          edges {
            node {
              id
              approvedBy {
                id
                username
              }
              approvedAt
            }
          }
        }
        hasCompleteApprovals
      }
    `,
  },
);

const modifiedComp = createQueryRendererProgress(
  query,
  container,
  (viewer, outerProps) => ({ bankAccount: viewer && viewer.bankAccounts.edges[0].node }),
  ({ bankAccountId }) => ({ bankAccountId }),
);


function BankAccountVerification(props) {
  const { bankAccount } = props;
  const BankAccountVerificationDataComp = modifiedComp;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const classes = useCommonStyles();
  return (
    <div>
      <Drawer
        open={drawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.drawerRoot}>
          <BankAccountVerificationDataComp
            bankAccountId={bankAccount.id}
          />
        </div>
      </Drawer>
      <MoreButton
        onClick={() => setDrawerOpen(true)}
      />
    </div>
  );
}

export default BankAccountVerification;
