/**
 * @flow
 * @relayHash c83a29a1f4f571ff913fb7aee225788d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TenantAccountType = "ADE" | "ADF" | "BDR" | "COM" | "CON" | "COR" | "FXC" | "GST" | "IBF" | "INP" | "INT" | "MGN" | "OFE" | "PLF" | "PRM" | "REM" | "SIP" | "SPT" | "SUB" | "SUM" | "TRA" | "VUF" | "%future added value";
export type accountLoadedQueryVariables = {|
  accountId?: ?string,
  first?: ?number,
|};
export type accountLoadedQueryResponse = {|
  +viewer: ?{|
    +accounts: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +clientaccount: ?{|
            +id: string
          |},
          +name: ?string,
          +id: string,
          +tenantaccount: ?{|
            +id: string,
            +accountType: ?TenantAccountType,
          |},
        |}
      |}>
    |}
  |}
|};
export type accountLoadedQuery = {|
  variables: accountLoadedQueryVariables,
  response: accountLoadedQueryResponse,
|};
*/


/*
query accountLoadedQuery(
  $accountId: ID
  $first: Int
) {
  viewer {
    accounts(id: $accountId, first: $first) {
      edges {
        node {
          clientaccount {
            id
          }
          name
          id
          tenantaccount {
            id
            accountType
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "accountId",
    "type": "ID",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "accounts",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first"
    },
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "accountId"
    }
  ],
  "concreteType": "AccountNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "AccountNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "AccountNode",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "clientaccount",
              "storageKey": null,
              "args": null,
              "concreteType": "ClientAccountNode",
              "plural": false,
              "selections": [
                (v1/*: any*/)
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "tenantaccount",
              "storageKey": null,
              "args": null,
              "concreteType": "TenantAccountNode",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountType",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "accountLoadedQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "accountLoadedQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "accountLoadedQuery",
    "id": null,
    "text": "query accountLoadedQuery(\n  $accountId: ID\n  $first: Int\n) {\n  viewer {\n    accounts(id: $accountId, first: $first) {\n      edges {\n        node {\n          clientaccount {\n            id\n          }\n          name\n          id\n          tenantaccount {\n            id\n            accountType\n          }\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f82ac22ef6bf0dcfeb8cb5340374579';
module.exports = node;
