import * as React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { Decimal } from 'decimal.js';
import PropTypes from 'prop-types';
import DrawerGridItemHeading from '../../common/drawer/DrawerGridItemHeading';
import DrawerGridItemContainer from '../../common/drawer/DrawerGridItemContainer';
import { createQueryRendererProgressV2 } from '../../common/QueryRendererProgressV2';
import { isRecurNull } from '../../../common/helpers';
import DrawerGridItemBottomButtonContainer from '../../common/drawer/DrawerGridItemBottomButtonContainer';
import { BatchButton } from '../../common/buttons/BaseButton';
import { doCommitPromise } from '../../../common/commit';
import createCashTransferRequestInverseBatch from '../../../mutations/CreateCashTransferRequestInverseBatch';
import { toLocal } from '../../../helpers/ID';
import DrawerGridItemSubGroupHeading from '../../common/drawer/DrawerGridItemSubGroupHeading';
import { VerticalTableWidget } from '../../common/VerticalTableWidget';
import DrawerGridItemSelectField from '../../common/drawer/DrawerGridItemSelectField';
import DateTime from '../../common/DateTimeMoment';
import createCashTransferRequestContraBatch from '../../../mutations/CreateCashTransferRequestContraBatch';


function BatchDetailsFormBase(props) {
  const {
    viewer,
    onAdd,
    contraCustodian: inContraCustodian,
    contraOnly,
  } = props;
  const batch = isRecurNull(viewer, ['cashTransferRequestBatches', 'edges', 0, 'node']);

  const initTxnDefalut = batch.transactionInitiationSupported;
  const [contraCustodian, setContraCustodian] = React.useState(inContraCustodian);
  const [initiateCashTransaction] = React.useState(initTxnDefalut);
  const total = batch.cashtransferrequestSet.edges.reduce(
    (v, c) => v.plus(new Decimal(c.node.amount)),
    new Decimal(0),
  );

  const showTransferOut = !batch.inverseBatch && !!batch.contraBatch && !contraOnly;

  return (
    <DrawerGridItemContainer>
      <DrawerGridItemHeading heading="Batch Details" />
      <VerticalTableWidget
        columnMeta={[
          ['Name', 'name'],
          ['Count', node => node.cashtransferrequestSet.edges.length],
          ['Total', () => total.toString()],
          ['Contra Only', () => (contraOnly ? 'Yes' : 'No')],
          ['Inverse batch', node => node.inverseBatch && toLocal(batch.inverseBatch.id)],
          ['Contra batch', node => node.contraBatch && toLocal(batch.contraBatch.id)],
          ['Created At', node => (<DateTime>{node.createdAt}</DateTime>)],
        ]}
        dataNode={batch}
        title="Summary"
        xs={12}
        showHeadings={false}
      />


      {/*<DrawerGridItemParagraph sm={12}>*/}
      {/*  If &quot;Generate Requests&quot; is deselected it will not create*/}
      {/*  the corresponding cash transfer requests in&nbsp; &quot;*/}
      {/*  <EnumValue enumValue={contraCustodian} enumType="Custodian" />*/}
      {/*  &quot; side*/}
      {/*</DrawerGridItemParagraph>*/}
      {/*{*/}
      {/*  !contraOnly && (*/}
      {/*    <DrawerGridItemCheckBox*/}
      {/*      xs={12}*/}
      {/*      label="Generate Requests"*/}
      {/*      onChange={e => setCreateContraBatch(e.target.checked)}*/}
      {/*      value={createContraBatch}*/}
      {/*    />*/}
      {/*  )*/}
      {/*}*/}

      {/*{batch.transactionInitiationSupported && (*/}
      {/*  <>*/}
      {/*    <DrawerGridItemParagraph sm={12}>*/}
      {/*      Will create cash transaction for paper version and*/}
      {/*      initiate the bank transfer in live version*/}
      {/*    </DrawerGridItemParagraph>*/}
      {/*    <DrawerGridItemCheckBox*/}
      {/*      label="Initiate Transfer"*/}
      {/*      helperText="Initiate a cash transaction for the batch"*/}
      {/*      onChange={e => setInitiateCashTransaction(e.target.checked)}*/}
      {/*      value={initiateCashTransaction}*/}
      {/*    />*/}
      {/*  </>*/}
      {/*)}*/}

      <DrawerGridItemSubGroupHeading heading="Batch Transfer" />
      <DrawerGridItemSelectField
        xs={12}
        disabled
        value={contraCustodian}
        enumType="Custodian"
        onChange={e => setContraCustodian(e.target.value)}
        label="Transfer To"
      />
      <DrawerGridItemBottomButtonContainer>
        <Grid item>
          <BatchButton
            disabled={contraOnly ? batch.inverseBatch : batch.contraBatch}
            buttonText="Transfer"
            onClickPromise={() => {
              if (contraOnly) {
                return doCommitPromise(
                  createCashTransferRequestContraBatch,
                  {
                    batchId: batch.id,
                    name: `Cont:${toLocal(batch.id)}`,
                    custodian: contraCustodian,
                  },
                );
              }

              return doCommitPromise(
                createCashTransferRequestInverseBatch,
                {
                  batchId: batch.id,
                  name: `Client:${toLocal(batch.id)}`,
                  initiateCashTransaction,
                  contraBatch: {
                    custodian: contraCustodian,
                    name: `Client Cont:${toLocal(batch.id)}`,
                  },
                },
              );
            }}
          />
        </Grid>
      </DrawerGridItemBottomButtonContainer>

      { showTransferOut && (
        <>
          <DrawerGridItemSubGroupHeading heading="Client Transfer" />
          <DrawerGridItemBottomButtonContainer>
            <Grid item>
              <BatchButton
                buttonText="Transfer to Clients"
                onClickPromise={() => doCommitPromise(
                  createCashTransferRequestInverseBatch,
                  {
                    batchId: batch.id,
                    name: `Client:${toLocal(batch.id)}`,
                    initiateCashTransaction: true,
                  },
                  () => (onAdd && onAdd()),
                  () => null,
                )}
              />
            </Grid>
          </DrawerGridItemBottomButtonContainer>
        </>
      )}

    </DrawerGridItemContainer>
  );
}


BatchDetailsFormBase.propTypes = {
  contraOnly: PropTypes.bool,
};

BatchDetailsFormBase.defaultProps = {
  contraOnly: false,
};

const query = graphql`
  query BatchDetailsFormBaseQuery (
    $batchId: ID
  ) {
    viewer {
      ...BatchDetailsFormBase_viewer
      @arguments (
        batchId: $batchId
      )
    }
  }
`;

const container = createFragmentContainer(
  BatchDetailsFormBase,
  {
    viewer: graphql`
      fragment BatchDetailsFormBase_viewer on Query
      @argumentDefinitions(
        batchId: {type: ID}
      )
      {
        cashTransferRequestBatches(id: $batchId) {
          edges {
            node {
              id
              name
              transactionInitiationSupported
              createdAt
              updatedAt
              custodian
              
              inverseBatch {
                id
                name
              }
              
              contraBatch {
                id
                name
              }
              
              cashtransferrequestSet {
                edges {
                  node {
                    id
                    status
                    amount
                  }
                }
              }
            }
          }
        }
      }
    `,
  },
);


export default createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ batchId }) => ({ batchId }),
);
