/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type BankAccountStatus = "DIS" | "PEN" | "VER" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type BankAccounts_account$ref: FragmentReference;
declare export opaque type BankAccounts_account$fragmentType: BankAccounts_account$ref;
export type BankAccounts_account = {|
  +bankAccounts: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +holderName: string,
        +bsbNumber: string,
        +accountNumber: string,
        +status: BankAccountStatus,
        +dividendPayout: boolean,
      |}
    |}>
  |},
  +$refType: BankAccounts_account$ref,
|};
export type BankAccounts_account$data = BankAccounts_account;
export type BankAccounts_account$key = {
  +$data?: BankAccounts_account$data,
  +$fragmentRefs: BankAccounts_account$ref,
};
*/


const node/*: ReaderFragment*/ = {
  "kind": "Fragment",
  "name": "BankAccounts_account",
  "type": "AccountNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "bankAccounts",
      "storageKey": null,
      "args": null,
      "concreteType": "BankAccountNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "BankAccountNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "BankAccountNode",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "id",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "holderName",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "bsbNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "accountNumber",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "dividendPayout",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '7786920965af7b4946c5ae24c44c5523';
module.exports = node;
