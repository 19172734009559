import React from 'react';
import { withRouter } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import { TableHead } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { graphql } from 'react-relay';
import { commonStyles } from '../common/Styles';
import DateTime from '../common/DateTimeMoment';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import { fromGlobalId, toLocal } from '../../helpers/ID';
import MoreButton from '../common/buttons/MoreButton';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
}));


function VueHistory(props) {
  const classes = useStyles();
  const { vue, filter, relay } = props;
  filter.setRelay(relay);
  return (
    <div className={classes.innerTabRoot}>
      <Paper style={{ overflow: 'auto' }}>
        <div className={classes.paginatedTableHolderRoot} key="paginatedTableHolderInnerRoot">
          <Table size="small">
            <TableHead>
              <TableCell>
                ID
              </TableCell>
              <TableCell>
                Name
              </TableCell>
              <TableCell>
                Contracts
              </TableCell>
              <TableCell>
                Created At
              </TableCell>
              <TableCell>
                Published At
              </TableCell>
              <TableCell />
            </TableHead>
            <TableBody>
              {
                vue.vueSet.edges.map(
                  childVue => (
                    <TableRow>
                      <TableCell>
                        {toLocal(childVue.node.id)}
                      </TableCell>
                      <TableCell>
                        {childVue.node.name}
                      </TableCell>
                      <TableCell>
                        <Typography variant="caption" component="h2">
                          {
                            childVue.node.vuecontractSet.edges.map(
                              (vueContract, i) => (
                                <React.Fragment>
                                  {i !== 0 && (<span>&nbsp;&bull;&nbsp;</span>)}
                                  {`${vueContract.node.contract.symbol}`}
                                </React.Fragment>
                              ),
                            )
                          }
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <DateTime>{childVue.node.createdAt}</DateTime>
                      </TableCell>
                      <TableCell>
                        <DateTime>{childVue.node.publishedAt}</DateTime>
                      </TableCell>
                      <TableCell>
                        <MoreButton href={`/vue/${fromGlobalId(childVue.node.id)[1]}`} />
                      </TableCell>
                    </TableRow>
                  ),
                )
              }

            </TableBody>
          </Table>
        </div>
      </Paper>
    </div>
  );
}

const query = graphql`
  query VueHistoryQuery (
    $vueId: ID,
  ) {
    viewer {
      vues (
        id: $vueId
      ){
        edges{
          node {
            createdAt 
            updatedAt
            published
            publishedAt
            
            vueSet (
              orderBy: "-publishedAt"
            ) {
              edges {
                node {
                  id
                  name
                  createdAt 
                  published
                  publishedAt
                  vuecontractSet {
                    edges {
                      node {
                        contract {
                          symbol
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withRouter(createQueryRendererProgressV2(
  query,
  VueHistory,
  (outerProps, { viewer }) => ({ vue: viewer.vues.edges[0].node, viewer }),
  props => ({ vueId: props.vueId }),
));
