import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableStatus from '../common/TableStatus';
import EnumValue from '../common/EnumValue';
import CreditSenseIcon from '../application/au/CreditSenseIcon';

const useStyles = makeStyles(theme => ({
  verifiedContainer: {
    background: '#54AB78', 
    padding: '2px 10px', 
    width: 'fit-content', 
    color: 'white', 
    display: 'flex', 
    gap: 3, 
    alignItems: 'center', 
    borderRadius: 20, 
    fontSize: 12, 
    float: 'left',
  },
}));

const creditSenseColor = {
  VER: { color: 'primary' },
  PEN: {},
  FLD: { color: 'secondary' },
  UNM: { color: 'secondary' },
};

function CreditSenseStatus(props) {
  const { creditSense } = props;
  const classes = useStyles();

  if (!creditSense) return ' ';

  if (creditSense.status === 'VER') {
    return (
      <div className={classes.verifiedContainer}>
        <CreditSenseIcon />
        Verified
      </div>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item>
        <TableStatus {...creditSenseColor[creditSense.status]} customStyle={{ textTransform: 'capitalize' }}>
          <EnumValue
            enumType="CreditsenseVerificationStatus"
            enumValue={creditSense.status}
          />
        </TableStatus>
      </Grid>
    </Grid>
  );
}

export default CreditSenseStatus;
