import { graphql } from 'react-relay';
import { createQueryRendererProgressV2 } from '../QueryRendererProgressV2';
import { isRecurNull } from '../../../common/helpers';

const query = graphql`
  query accountLoadedQuery(
    $accountId: ID
    $first: Int
  ) {
    viewer {
      accounts(
        id: $accountId
        first: $first,
      ) {
        edges {
          node {
            clientaccount {
              id
            }
            name
            id
            tenantaccount {
              id
              accountType
            }
          }
        }
      }
    }
  }
`;


const withAccountInfoLoaded = component => createQueryRendererProgressV2(
  query,
  component,
  ({ accountId }, { viewer }) => ({
    inAccount: (accountId && isRecurNull(viewer, ['accounts', 'edges', 0, 'node'])),
  }),
  ({ accountId }) => ({
    accountId,
    first: accountId ? 1 : 0,
  }),
);

export default withAccountInfoLoaded;
