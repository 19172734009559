import { graphql } from 'react-relay';

const createBankAccountMutation = graphql`
  mutation CreateBankAccountMutation (
      $accountId: ID!
      $bsbNumber: String
      $accountNumber: String
      $holderName: String
      $files: [Upload]
  ) {
      createBankAccount(
          accountId: $accountId
          bsbNumber: $bsbNumber
          accountNumber: $accountNumber
          holderName: $holderName
          files: $files
      ) {
          bankAccount {
              id
              bsbNumber
              accountNumber
              holderName
              account {
                  ...AccountProfile_account
              }
          }
      }
  }
`;

export default createBankAccountMutation;
