import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import { commonStyles } from '../common/Styles';
import { AddButton } from '../common/buttons/BaseButton';
import PopupAccountSelectorField from '../common/PopupAccountSelectorField';
import DrawerGridItemContainer from '../common/drawer/DrawerGridItemContainer';
import DrawerGridItemHeading from '../common/drawer/DrawerGridItemHeading';
import DrawerGridItemSelectField from '../common/drawer/DrawerGridItemSelectField';
import DrawerGridItemTextField from '../common/drawer/DrawerGridItemTextField';
import DrawerGridItemBottomButtonContainer
  from '../common/drawer/DrawerGridItemBottomButtonContainer';
import { doCommitPromise } from '../../common/commit';
import createWithdrawalRequestMutation from '../../mutations/CreateWithdrawalRequestMutation';
import withSuspense from '../common/table/Suspention';
import AdminMetaContext from '../adminPanel/AdminMetaContext';
import withAccountInfoLoaded from '../common/hoc/accountLoaded';

const useStyles = makeStyles(theme => ({
  ...commonStyles(theme),
  bankAccountTableWrapper: {
    marginTop: '30px',
    minHeight: '200px',
  },
}));


function AddWithdrawalFormBase(props) {
  const {
    onAdd,
    onClose,
    custodian: inCustodian,
    inAccount,
  } = props;
  const classes = useStyles();
  const adminMeta = React.useContext(AdminMetaContext);
  const [currency, setCurrency] = React.useState(adminMeta.baseCurrency);
  const [account, setAccount] = React.useState(inAccount);
  const [bankAccount, setBankAccount] = React.useState(null);
  const [custodian, setCustodian] = React.useState(inCustodian);
  const [members] = React.useState({
    amount: 0,
  });
  let bankAccountDesc = null;
  if (bankAccount) {
    bankAccountDesc = `${bankAccount.bsbNumber}-${bankAccount.accountNumber}`;
  }
  const MiniBankAccountList = withSuspense(React.lazy(
    () => (process.env.DEPLOYMENT_CODE === 'AU'
      ? import('../common/au/MiniBankAccountList') : import('../common/uk/MiniBankAccountList')),
  ));

  return (
    <>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading heading="Add Withdrawal" />

        <Grid item xs={12}>
          <PopupAccountSelectorField
            fullWidth
            defaultValue={account}
            onSelect={(a) => {
              setAccount(a);
            }}
          />
        </Grid>

        <DrawerGridItemSelectField
          enumType="Currency"
          value={currency}
          onChange={e => setCurrency(e.target.value)}
        />

        <DrawerGridItemTextField
          label="Amount"
          type="number"
          defaultValue={members.amount}
          onChange={(e) => {
            members.amount = e.target.value;
          }}
        />

        <DrawerGridItemTextField
          value={bankAccountDesc}
          label="BankAccount"
          disabled
        />

        <DrawerGridItemSelectField
          label="Custodian"
          enumType="Custodian"
          value={custodian}
          required
          onChange={e => setCustodian(e.target.value)}
        />

        <Grid item xs={12}>
          <div className={classes.bankAccountTableWrapper}>
            {
              account && (
                <MiniBankAccountList
                  clientAccountId={account.id}
                  onSelectBackAccount={ba => setBankAccount(ba)}
                />)
            }
          </div>
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              onClickPromise={() => doCommitPromise(
                createWithdrawalRequestMutation,
                {
                  accountId: account.id,
                  amount: -Number(members.amount),
                  currency,
                  bankAccountId: bankAccount.id,
                  custodian,
                },
                () => ((onAdd && onAdd()) || (onClose && onClose())),
              )}
              disabled={!bankAccount}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>
      </DrawerGridItemContainer>
    </>
  );
}

export default withAccountInfoLoaded(AddWithdrawalFormBase);
