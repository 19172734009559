/**
 * @flow
 * @relayHash 63f8c3e3dde5908941b1fe8fc8e0b423
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BankAccountStatus = "DIS" | "PEN" | "VER" | "%future added value";
export type BankAccountVerificationQueryVariables = {|
  bankAccountId?: ?string
|};
export type BankAccountVerificationQueryResponse = {|
  +viewer: ?{|
    +bankAccounts: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +id: string,
          +holderName: string,
          +bsbNumber: string,
          +accountNumber: string,
          +createdAt: any,
          +updatedAt: any,
          +status: BankAccountStatus,
          +dividendPayout: boolean,
          +account: {|
            +id: string,
            +name: ?string,
          |},
          +bankaccountdocumentSet: {|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +file: string,
                +fileName: string,
                +fileUrl: ?string,
                +updatedAt: any,
                +uploadedBy: ?{|
                  +fullName: ?string
                |},
              |}
            |}>
          |},
          +approvals: ?{|
            +edges: $ReadOnlyArray<?{|
              +node: ?{|
                +id: string,
                +approvedBy: {|
                  +id: string,
                  +username: string,
                |},
                +approvedAt: any,
              |}
            |}>
          |},
          +hasCompleteApprovals: ?boolean,
        |}
      |}>
    |}
  |}
|};
export type BankAccountVerificationQuery = {|
  variables: BankAccountVerificationQueryVariables,
  response: BankAccountVerificationQueryResponse,
|};
*/


/*
query BankAccountVerificationQuery(
  $bankAccountId: ID
) {
  viewer {
    bankAccounts(id: $bankAccountId) {
      edges {
        node {
          id
          holderName
          bsbNumber
          accountNumber
          createdAt
          updatedAt
          status
          dividendPayout
          account {
            id
            name
          }
          bankaccountdocumentSet {
            edges {
              node {
                id
                file
                fileName
                fileUrl
                updatedAt
                uploadedBy {
                  fullName
                  id
                }
              }
            }
          }
          approvals {
            edges {
              node {
                id
                approvedBy {
                  id
                  username
                }
                approvedAt
              }
            }
          }
          hasCompleteApprovals
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "bankAccountId",
    "type": "ID",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "bankAccountId"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "holderName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "bsbNumber",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "accountNumber",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "dividendPayout",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "account",
  "storageKey": null,
  "args": null,
  "concreteType": "AccountNode",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    }
  ]
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "file",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileName",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fileUrl",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fullName",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "approvals",
  "storageKey": null,
  "args": null,
  "concreteType": "ApprovalNodeConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "edges",
      "storageKey": null,
      "args": null,
      "concreteType": "ApprovalNodeEdge",
      "plural": true,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "node",
          "storageKey": null,
          "args": null,
          "concreteType": "ApprovalNode",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "approvedBy",
              "storageKey": null,
              "args": null,
              "concreteType": "UserNode",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "username",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "approvedAt",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "hasCompleteApprovals",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "BankAccountVerificationQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bankAccounts",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "BankAccountNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BankAccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BankAccountNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bankaccountdocumentSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BankAccountDocumentNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BankAccountDocumentNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BankAccountDocumentNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "uploadedBy",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "BankAccountVerificationQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "bankAccounts",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "BankAccountNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "BankAccountNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "BankAccountNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "bankaccountdocumentSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "BankAccountDocumentNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "BankAccountDocumentNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "BankAccountDocumentNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v11/*: any*/),
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  (v7/*: any*/),
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "uploadedBy",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "UserNode",
                                    "plural": false,
                                    "selections": [
                                      (v14/*: any*/),
                                      (v2/*: any*/)
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      (v15/*: any*/),
                      (v16/*: any*/)
                    ]
                  }
                ]
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "BankAccountVerificationQuery",
    "id": null,
    "text": "query BankAccountVerificationQuery(\n  $bankAccountId: ID\n) {\n  viewer {\n    bankAccounts(id: $bankAccountId) {\n      edges {\n        node {\n          id\n          holderName\n          bsbNumber\n          accountNumber\n          createdAt\n          updatedAt\n          status\n          dividendPayout\n          account {\n            id\n            name\n          }\n          bankaccountdocumentSet {\n            edges {\n              node {\n                id\n                file\n                fileName\n                fileUrl\n                updatedAt\n                uploadedBy {\n                  fullName\n                  id\n                }\n              }\n            }\n          }\n          approvals {\n            edges {\n              node {\n                id\n                approvedBy {\n                  id\n                  username\n                }\n                approvedAt\n              }\n            }\n          }\n          hasCompleteApprovals\n        }\n      }\n    }\n    id\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5da19bea54944690f7a923c704002e39';
module.exports = node;
