/**
 * @flow
 * @relayHash 3044c8d022f57be627c93b1bdcdb47e8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserList_viewer$ref = any;
export type UserListQueryVariables = {|
  first?: ?number,
  offset?: ?number,
  search?: ?string,
  firstName?: ?string,
  lastName?: ?string,
  isStaff?: ?boolean,
  orderBy?: ?string,
|};
export type UserListQueryResponse = {|
  +viewer: ?{|
    +$fragmentRefs: UserList_viewer$ref
  |}
|};
export type UserListQuery = {|
  variables: UserListQueryVariables,
  response: UserListQueryResponse,
|};
*/


/*
query UserListQuery(
  $first: Int
  $offset: Int
  $search: String
  $firstName: String
  $lastName: String
  $isStaff: Boolean
  $orderBy: String
) {
  viewer {
    ...UserList_viewer_4jLrgP
    id
  }
}

fragment UserList_viewer_4jLrgP on Query {
  authUsers(first: $first, offset: $offset, search: $search, firstName: $firstName, lastName: $lastName, isStaff: $isStaff, orderBy: $orderBy) {
    edges {
      node {
        id
        firstName
        lastName
        lastLogin
        username
        email
        isStaff
        isSuperuser
        isActive
        dateJoined
        usersubscriptionSet {
          edges {
            node {
              id
              billPeriodEnd
              active
            }
          }
        }
        tenant {
          id
          name
        }
        adviser {
          id
          firstName
          lastName
          mobileNumber
        }
        usermodel {
          mobileNumber
          parsedMobileNumber {
            rawNumber
            e164Number
            countryCode
            countrySymbol
            nationalNumber
            isPossibleNumber
            isValidNumber
          }
          accounts {
            edges {
              node {
                id
                name
              }
            }
          }
          id
        }
      }
    }
    count
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "search",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "firstName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "lastName",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "isStaff",
    "type": "Boolean",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "firstName",
    "variableName": "firstName"
  },
  {
    "kind": "Variable",
    "name": "isStaff",
    "variableName": "isStaff"
  },
  {
    "kind": "Variable",
    "name": "lastName",
    "variableName": "lastName"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "name",
    "args": null,
    "storageKey": null
  }
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "mobileNumber",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "UserListQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "UserList_viewer",
            "args": (v1/*: any*/)
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UserListQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Query",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "authUsers",
            "storageKey": null,
            "args": (v1/*: any*/),
            "concreteType": "UserNodeConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "edges",
                "storageKey": null,
                "args": null,
                "concreteType": "UserNodeEdge",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "node",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "UserNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "lastLogin",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "username",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isStaff",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isSuperuser",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "isActive",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "dateJoined",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "usersubscriptionSet",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserSubscriptionNodeConnection",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "edges",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "UserSubscriptionNodeEdge",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "node",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "UserSubscriptionNode",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "billPeriodEnd",
                                    "args": null,
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "ScalarField",
                                    "alias": null,
                                    "name": "active",
                                    "args": null,
                                    "storageKey": null
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "tenant",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "TenantNode",
                        "plural": false,
                        "selections": (v5/*: any*/)
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "adviser",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "AdviserNode",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v6/*: any*/)
                        ]
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "usermodel",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "UserModelNode",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "parsedMobileNumber",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ParsedMobileNumber",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "rawNumber",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "e164Number",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "countryCode",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "countrySymbol",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "nationalNumber",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "isPossibleNumber",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "isValidNumber",
                                "args": null,
                                "storageKey": null
                              }
                            ]
                          },
                          {
                            "kind": "LinkedField",
                            "alias": null,
                            "name": "accounts",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "ClientAccountNodeConnection",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "LinkedField",
                                "alias": null,
                                "name": "edges",
                                "storageKey": null,
                                "args": null,
                                "concreteType": "ClientAccountNodeEdge",
                                "plural": true,
                                "selections": [
                                  {
                                    "kind": "LinkedField",
                                    "alias": null,
                                    "name": "node",
                                    "storageKey": null,
                                    "args": null,
                                    "concreteType": "ClientAccountNode",
                                    "plural": false,
                                    "selections": (v5/*: any*/)
                                  }
                                ]
                              }
                            ]
                          },
                          (v2/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "count",
                "args": null,
                "storageKey": null
              }
            ]
          },
          (v2/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "UserListQuery",
    "id": null,
    "text": "query UserListQuery(\n  $first: Int\n  $offset: Int\n  $search: String\n  $firstName: String\n  $lastName: String\n  $isStaff: Boolean\n  $orderBy: String\n) {\n  viewer {\n    ...UserList_viewer_4jLrgP\n    id\n  }\n}\n\nfragment UserList_viewer_4jLrgP on Query {\n  authUsers(first: $first, offset: $offset, search: $search, firstName: $firstName, lastName: $lastName, isStaff: $isStaff, orderBy: $orderBy) {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        lastLogin\n        username\n        email\n        isStaff\n        isSuperuser\n        isActive\n        dateJoined\n        usersubscriptionSet {\n          edges {\n            node {\n              id\n              billPeriodEnd\n              active\n            }\n          }\n        }\n        tenant {\n          id\n          name\n        }\n        adviser {\n          id\n          firstName\n          lastName\n          mobileNumber\n        }\n        usermodel {\n          mobileNumber\n          parsedMobileNumber {\n            rawNumber\n            e164Number\n            countryCode\n            countrySymbol\n            nationalNumber\n            isPossibleNumber\n            isValidNumber\n          }\n          accounts {\n            edges {\n              node {\n                id\n                name\n              }\n            }\n          }\n          id\n        }\n      }\n    }\n    count\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd0cbae41214201bdb9e926c1561a19de';
module.exports = node;
