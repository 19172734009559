import * as React from 'react';
import { Grid } from '@material-ui/core';
import { useCommonStyles } from '../../../common/Styles';
import DrawerGridItemContainer from '../../../common/drawer/DrawerGridItemContainer';
import DrawerGridItemTextField from '../../../common/drawer/DrawerGridItemTextField';
import DrawerGridItemBottomButtonContainer from '../../../common/drawer/DrawerGridItemBottomButtonContainer';
import { AddButton } from '../../../common/buttons/BaseButton';
import { doCommitPromise } from '../../../../common/commit';
import createBankAccountMutation from '../../../../mutations/CreateBankAccountMutation';
import DrawerGridItemHeading from '../../../common/drawer/DrawerGridItemHeading';
import DrawerGridItemSubGroupHeading from '../../../common/drawer/DrawerGridItemSubGroupHeading';
import FileUpload from '../../../common/FileUpload';
import { makeUploadsTopArray } from '../../../../common/helpers';

function AddBankAccountFrom(props) {
  const { account, onClose } = props;
  const classes = useCommonStyles();
  const [files, setFiles] = React.useState([]);
  const [members] = React.useState({
    holderName: null,
    bsbNumber: null,
    accountNumber: null,
  });

  return (
    <div className={classes.drawerRoot}>
      <DrawerGridItemContainer>
        <DrawerGridItemHeading
          heading="Add Bank Account"
        />
        <DrawerGridItemTextField
          xs={12}
          label="Holder Name"
          onChange={(e) => {
            members.holderName = e.target.value;
          }}
        />
        <DrawerGridItemTextField
          label="BSB"
          onChange={(e) => {
            members.bsbNumber = e.target.value;
          }}
        />
        <DrawerGridItemTextField
          label="Account Number"
          onChange={(e) => {
            members.accountNumber = e.target.value;
          }}
        />

        <DrawerGridItemSubGroupHeading heading="Verification Documents" />
        <Grid item xs={12}>
          <FileUpload
            onChange={(filesIn) => {
              setFiles([...files, ...filesIn]);
            }}
            displayList={files.length > 0}
          />
        </Grid>

        <DrawerGridItemBottomButtonContainer>
          <Grid item>
            <AddButton
              onClickPromise={() => doCommitPromise(
                createBankAccountMutation,
                {
                  accountId: account.id,
                  bsbNumber: members.bsbNumber,
                  holderName: members.holderName,
                  accountNumber: members.accountNumber,
                  files: files.map(() => null),
                },
                () => (onClose && onClose()),
                () => null,
                null,
                makeUploadsTopArray(files),
              )}
            />
          </Grid>
        </DrawerGridItemBottomButtonContainer>

      </DrawerGridItemContainer>
    </div>
  );
}

export default AddBankAccountFrom;
