import React from 'react';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import TableFieldMeta from '../../../common/TableFieldMeta';
import { useCommonStyles } from '../Styles';
import InnerTabFilterGridItemTextField from '../innerTab/InnerTabFilterGridItemTextField';
import BaseGridContainer from '../BaseGridContainer';
import TenantLink from '../links/TenantLink';
import { createQueryRendererProgressV2 } from '../QueryRendererProgressV2';
import TablePaginator from '../TablePaginator';

function AdviserSearch(props) {
  const {
    relay,
    viewer,
    onSelectItems,
    closeOnSingleSelect,
    postColumns,
    filter,
  } = props;

  filter.setRelay(relay);
  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.field('firstName', 'First Name'),
    TableFieldMeta.field('lastName', 'Last Name'),
    TableFieldMeta.field('email', 'Email'),
    TableFieldMeta.field('username', 'Username'),
    TableFieldMeta.customCell('tenant', 'Tenant', row => (
      <TenantLink tenant={row.value.parentTenant} />
    )),
  ];

  if (postColumns) columns.push(...postColumns);
  if (onSelectItems) columns.push(TableFieldMeta.selectItem(onSelectItems));

  if (viewer && viewer.contracts && viewer.advisers.edges.length === 1 && closeOnSingleSelect) {
    if (onSelectItems) {
      onSelectItems([viewer.advisers.edges[0].node]);
    }
  }

  return (
    <div>
      <div className={classes.formControlContainer}>
        <BaseGridContainer>
          <InnerTabFilterGridItemTextField
            id="search-filter"
            label="Custom Search"
            helperText="Name, e-mail, ID, username"
            onChange={e => filter.updateVar('search', e.target.value)}
          />
        </BaseGridContainer>
      </div>

      <TablePaginator
        columns={columns}
        getDataNode={() => (viewer ? viewer.advisers : null)}
        filter={filter}
      />
    </div>
  );
}


const query = graphql`
  query AdviserSearchQuery (
    $first: Int
    $offset: Int
    $search: String
    $tenantId: ID
    $orderBy: String
  ) {
    viewer {
      ...AdviserSearch_viewer
      @arguments (
        first: $first
        offset: $offset
        search: $search
        tenantId: $tenantId
        orderBy: $orderBy
      )
    }
  }
`;

const container = createRefetchContainer(
  AdviserSearch,
  {
    viewer: graphql`
      fragment AdviserSearch_viewer on Query      
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        search: {type: String}
        tenantId: {type: ID}
        orderBy: {type: String}
      ) {
        advisers(
          first: $first
          offset: $offset
          parentTenant: $tenantId
          search: $search
          orderBy: $orderBy
        ) {
          count
          edges {
            node {
              id
              firstName
              userPtr {
                fullName
              }
              lastName
              email
              username
              parentTenant {
                id
                name
              }
            }
          }
        }
      }
    `,
  },
  query,
);

AdviserSearch.propTypes = {
  closeOnSingleSelect: PropTypes.bool,
  onSelectItems: PropTypes.func,
  postColumns: PropTypes.array,
};

AdviserSearch.defaultProps = {
  closeOnSingleSelect: false,
  onSelectItems: null,
  postColumns: [],
};

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({ tenantId }) => {
    const defaults = { orderBy: '-id' };
    if (tenantId) defaults.tenantId = tenantId;
    return defaults;
  },
  {
    debounceFields: ['search'],
  },
));
