import React from 'react';
import moment from 'moment-timezone';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  blue,
  deepOrange,
  deepPurple,
  lightGreen,
  orange,
  pink,
  red,
  teal,
} from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { Info } from '@material-ui/icons';
import versionInfo from '../../versionInfo.json';
import DateTime from '../common/DateTimeMoment';
import VersionInfoDlg from './VersionInfoDlg';

const systemColorMap = {
  prod: red,
  stag: blue,
  demo: orange,
  dev: teal,
  ukprod: pink,
  ukstag: lightGreen,
  ukdemo: deepOrange,
  ukdev: deepPurple,
};

const textCommons = {
  textAlign: 'center',
  fontSize: '11px',
  height: 17,
  color: '#414141',
  backgroundImage: 'linear-gradient(to top, #00000010 , #00000000)',
  paddingTop: 1,
};

const useStyles = makeStyles(theme => ({
  systemInfoContainer: {
    width: '100%',
    height: 64,
    display: 'flex',
    flexDirection: 'column',
  },
  systemInfoName: {
    paddingTop: 2,
    backgroundColor: 'grey',
    height: 30,
    textAlign: 'center',
    color: 'white',
  },
  infoIconContainer: {
    textAlign: 'center',
    backgroundImage: 'linear-gradient(to top, #00000010 , #00000000)',
  },
  typeInfo: {
    ...textCommons,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  systemInfoVersion: {
    ...textCommons,
    color: '#414141',
    backgroundColor: '#d5d5d5',
  },
  systemInfoCommit: {
    ...textCommons,
    color: '#919191',
    paddingTop: 2,
    fontSize: 10,
  },
}));

const miniTimeFmt = 'D MMM LT';


export default function VersionInfo(props) {
  const { adminMeta } = props;
  const classes = useStyles();
  let systemColor = '#919191';
  const systemColorOverride = systemColorMap[adminMeta.systemName.toLowerCase()];
  if (systemColorOverride) {
    systemColor = systemColorOverride['600'];
  }
  const [showVersionInfo, setShowVersionInfo] = React.useState(false);

  const latestVersion = adminMeta.versionInfo && adminMeta.versionInfo.latestVersion;
  const apiVersion = latestVersion && latestVersion.replace('.hotfix', '.hf');
  const labelCol = 17;
  const timeCol = 45;
  const versionCol = 100 - labelCol - timeCol;

  return (
    <div
      className={classes.systemInfoContainer}
    >
      <Grid container spacing={0}>
        <Grid
          item
          xs={10}
          className={classes.systemInfoName}
          style={{ backgroundColor: systemColor }}
        >
          <Typography variant="button" display="block" gutterBottom>
            {adminMeta.systemName}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          className={classes.infoIconContainer}
        >
          <VersionInfoDlg
            showVersionInfo={showVersionInfo}
            versionInfo={adminMeta.versionInfo}
            onClose={() => setShowVersionInfo(false)}
          />
          <IconButton
            style={{ padding: 5 }}
            onClick={e => setShowVersionInfo(true)}
          >
            <Info fontSize="small" />
          </IconButton>
        </Grid>

        {/* FE version information */}
        <div
          className={classes.typeInfo}
          style={{ flexBasis: `${labelCol}%` }}
        >
          FE
        </div>
        <div
          className={classes.systemInfoVersion}
          style={{ flexBasis: `${versionCol}%` }}
        >
          {versionInfo.versionTag && versionInfo.versionTag.replace('.hotfix', '.hf')}
        </div>
        <div
          className={classes.systemInfoCommit}
          style={{ flexBasis: `${timeCol}%` }}
        >
          <DateTime
            momentObject={moment(versionInfo.commitDate, 'YYYY-MM-DD hh:mm:ss Z').format('YYYY-MM-DDTHH:mm:ss')}
            outFormat={miniTimeFmt}
            tooltipHeading="Last Commit"
          />
        </div>

        {/* API version information */}
        <div
          className={classes.typeInfo}
          style={{ flexBasis: `${labelCol}%` }}
        >
          API
        </div>
        <div
          className={classes.systemInfoVersion}
          style={{ flexBasis: `${versionCol}%` }}
        >
          {apiVersion}
        </div>
        <div
          className={classes.systemInfoCommit}
          style={{ flexBasis: `${timeCol}%` }}
        >
          <DateTime
            tooltipHeading="Last Commit"
            outFormat={miniTimeFmt}
          >
            {adminMeta.versionInfo.commitAt}
          </DateTime>
        </div>


      </Grid>
    </div>
  );
}
