/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "BGN" | "CAD" | "CHF" | "CLP" | "CNH" | "CNY" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ISK" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "ROL" | "RON" | "RUB" | "SEK" | "SGD" | "USD" | "ZAR" | "%future added value";
export type FeeCalculationType = "MAX" | "MIN" | "SUM" | "%future added value";
export type FeeType = "ADE" | "ADF" | "ASB" | "AVH" | "BUF" | "CHI" | "CHIP" | "CHUP" | "CON" | "COR" | "CRDT" | "FXB" | "GST" | "INT" | "MGN" | "MSB" | "PLF" | "PLT" | "PRO" | "RTA" | "RTP" | "SIPP" | "STB" | "STBV" | "TAX" | "TRA" | "VHF" | "WDR" | "%future added value";
export type PaymentPeriod = "ANU" | "DAY" | "MON" | "ONE" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Fee_fee$ref: FragmentReference;
declare export opaque type Fee_fee$fragmentType: Fee_fee$ref;
export type Fee_fee = {|
  +id: string,
  +feeType: FeeType,
  +simpleTranche: boolean,
  +paymentPeriod: PaymentPeriod,
  +enabled: boolean,
  +createdAt: any,
  +updatedAt: any,
  +currency: ?Currency,
  +notes: ?{|
    +id: string
  |},
  +feeComponents: {|
    +edges: $ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +tenant: {|
          +id: string,
          +name: string,
          +baseCurrency: Currency,
        |},
        +calculationType: FeeCalculationType,
        +flatChargeAmount: ?number,
        +maxChargePoint: ?number,
        +minChargePoint: ?number,
        +minPriceChargePoint: ?number,
        +maxPriceChargePoint: ?number,
        +percentChargeAmount: ?number,
        +createdAt: any,
        +updatedAt: any,
      |}
    |}>
  |},
  +$refType: Fee_fee$ref,
|};
export type Fee_fee$data = Fee_fee;
export type Fee_fee$key = {
  +$data?: Fee_fee$data,
  +$fragmentRefs: Fee_fee$ref,
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "createdAt",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "updatedAt",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Fee_fee",
  "type": "FeeNode",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    (v0/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "feeType",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "simpleTranche",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "paymentPeriod",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "enabled",
      "args": null,
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "currency",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "notes",
      "storageKey": null,
      "args": null,
      "concreteType": "NotesNode",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ]
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "feeComponents",
      "storageKey": null,
      "args": null,
      "concreteType": "FeeComponentNodeConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "edges",
          "storageKey": null,
          "args": null,
          "concreteType": "FeeComponentNodeEdge",
          "plural": true,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "node",
              "storageKey": null,
              "args": null,
              "concreteType": "FeeComponentNode",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "tenant",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "TenantNode",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "name",
                      "args": null,
                      "storageKey": null
                    },
                    {
                      "kind": "ScalarField",
                      "alias": null,
                      "name": "baseCurrency",
                      "args": null,
                      "storageKey": null
                    }
                  ]
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "calculationType",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "flatChargeAmount",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "maxChargePoint",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "minChargePoint",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "minPriceChargePoint",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "maxPriceChargePoint",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "percentChargeAmount",
                  "args": null,
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/)
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd7c430a3fa55a03d47162d1d34ab8117';
module.exports = node;
