import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import {
  Link,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import SendIcon from '@material-ui/icons/Send';
import { useCommonStyles } from '../common/Styles';
import { createQueryRendererProgress } from '../common/QueryRendererProgress';
import TableStatus from '../common/TableStatus';
import { doCommitPromise } from '../../common/commit';
import executeCron from '../../mutations/Cron';
import CronExecutionForm from './CronExecutionForm';
import DataTableWidget from '../common/DataTableWidget';
import TableFieldMeta from '../../common/TableFieldMeta';
import MoreButton from '../common/buttons/MoreButton';
import TableCompactGridItem from '../common/table/TableCompactGridItem';
import TableIconButton from '../common/table/TableIconButton';
import CronOutputForm from './CronOutputForm';
import mvMutationErrors from '../../common/errorReport';

function CronList(props) {
  const { viewer } = props;
  const classes = useCommonStyles();
  const [cronToRun, setCronToRun] = React.useState(null);
  const [cronOutput, setCronOutput] = React.useState(null);
  return (
    <div className={classes.pageRoot}>
      <Drawer
        anchor="right"
        open={cronToRun}
        onClose={() => setCronToRun(null)}
      >
        <CronExecutionForm
          key={cronToRun && cronToRun.id}
          cron={cronToRun}
          onResponse={_cronOutput => setCronOutput(_cronOutput)}
        />
      </Drawer>

      <Drawer
        anchor="bottom"
        open={cronOutput}
        onClose={() => setCronOutput(null)}
      >
        <CronOutputForm
          key={setCronOutput}
          cronOutput={cronOutput}
          onTimeout={() => setCronOutput(null)}
        />
        {/*<Box style={{width: 'auto', height: 100}}>*/}

        {/*</Box>*/}
      </Drawer>

      <Grid container>
        <DataTableWidget
          xs={12}
          dataNodes={viewer.crons}
          columnMeta={[
            TableFieldMeta.fieldCell('exp', 'Expression',
              node => (node.value.expression || <TableStatus>Not Enabled</TableStatus>),
              { align: 'center' }),
            TableFieldMeta.fieldCell('aws', 'AWS', node => (node.value.expression && (
              <span>
                <Link href={node.value.awsEventUrl}>Event</Link>
                &nbsp;&bull;&nbsp;
                <Link href={node.value.awsLogUrl}>Log</Link>
              </span>
            ))),
            TableFieldMeta.fieldCell('run', 'Cron', node => (
              <Grid container spacing={1}>
                <TableCompactGridItem>
                  <TableIconButton
                    iconClass={SendIcon}
                    buttonText="Run"
                    tooltip="Run"
                    color={node.value.expression ? 'primary' : 'secondary'}
                    onClickPromise={() => doCommitPromise(
                      executeCron,
                      {
                        functionUrl: node.value.functionUrl,
                      },
                      (response) => {
                        if (!mvMutationErrors(response, 'executeCron')) {
                          setCronOutput(response.executeCron.response);
                        }
                      },
                      () => null,
                    )}
                  />
                </TableCompactGridItem>
                <Grid item>{node.value.functionUrl}</Grid>
              </Grid>
            )),
            TableFieldMeta.fieldCell('more', '', node => (
              <MoreButton
                key={`morebutton${node.value.id}`}
                onClick={() => setCronToRun(node.value)}
              />
            )),
          ]}
        />
      </Grid>
    </div>
  );
}

const query = graphql`
  query CronListQuery {
    viewer {
      ...CronList_viewer
    }
  }
`;

const container = createFragmentContainer(
  CronList,
  {
    viewer: graphql`
      fragment CronList_viewer on Query {
        adminMeta {
          systemName
        }
        crons {
          expression
          functionUrl
          awsLogUrl
          awsEventUrl
          paramDefs {
            defaultValue
            paramDefId: id
            type
            description
          }
        }
      }
    `,
  },
);

export default withRouter(
  createQueryRendererProgress(
    query,
    container,
  ),
);
