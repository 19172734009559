import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import BaseGridContainer from '../common/BaseGridContainer';
import { useCommonStyles } from '../common/Styles';
import TableFieldMeta from '../../common/TableFieldMeta';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';


function OffMarketTradeList(props) {
  const classes = useCommonStyles();
  const {
    viewer,
    relay,
    filter,
  } = props;
  filter.setRelay(relay);

  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.field(
      node => `${node.contract && node.contract.symbol}`,
      'Symbol', { align: 'center' },
    ),
    TableFieldMeta.field('quantity', 'Qty', { align: 'right' }),
    TableFieldMeta.field('price', 'Price', { align: 'right', width: 80 }),
    TableFieldMeta.account(node => node.account, 'Account', { charLimit: 40 }),
    TableFieldMeta.account(node => node.counterparty, 'CP. Account', { charLimit: 40 }),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>
        <Grid item xs={12}>
          <TablePaginator
            columns={columns}
            filter={filter}
            getDataNode={() => (viewer ? viewer.spotTrades : null)}
          />
        </Grid>
      </BaseGridContainer>
    </div>
  );
}


const query = graphql`
  query OffMarketTradeListQuery (
    $first: Int
    $offset: Int
    $accountId: ID
  ) {
    viewer {
      ...OffMarketTradeList_viewer
      @arguments (
        first: $first
        offset: $offset
        accountId: $accountId
      )
    }
  }
`;

const container = createRefetchContainer(
  OffMarketTradeList,
  {
    viewer: graphql`
      fragment OffMarketTradeList_viewer on Query
      @argumentDefinitions(
        first: {type: Int, defaultValue: 10 }
        offset: {type: Int, defaultValue: 0 }
        accountId: {type: ID}
      ) {
        spotTrades(
          first: $first
          offset: $offset
          orderBy: "-createdAt"
          account: $accountId
        ) {
          edges {
            node {
              id
              createdAt
              price
              proceeds
              quantity
              
              contract {
                id
                symbol
              }
              
              account {
                id
                name
              }
              
              counterparty {
                id
                name
              }
              
            }
          }
          count
        }
      }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  ({
    accountId,
  }) => ({
    accountId,
  }),
  {
    debounceFields: [],
  },
));
