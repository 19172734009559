import { graphql } from 'react-relay';


export const updateBankAccountStatus = graphql`
  mutation BankAccountStatusUpdateMutation(
    $bankAccountId: ID!
    $status: BankAccountStatus!
  ) {
    updateBankAccountStatus(
      bankAccountId: $bankAccountId,
      status: $status
    ) {
      bankAccount {
        ...BankAccountVerification_bankAccount
      }
    }
  }
`;


export const uploadBankAccountDocument = graphql`
  mutation BankAccountUploadDocumentMutation(
    $bankAccountId: ID
#    $files: [FileInputType]
     $files: [Upload]
  ) {
    uploadBankAccountDocument(
      bankAccountId: $bankAccountId,
      files: $files
    ) {
      bankAccount {
        ...BankAccountVerification_bankAccount
      }
    }
  }
`;


export const updateBankAccountDividendPayout = graphql`
  mutation BankAccountDividendPayoutUpdateMutation(
    $bankAccountId: ID!
    $dividendPayout: Boolean!
  ) {
    updateBankAccountDividendPayout(
      dividendPayout: $dividendPayout
      bankAccountId: $bankAccountId,
    ) {
      bankAccount {
        ...BankAccountVerification_bankAccount
      }
    }
  }
`;
