import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import { useCommonStyles } from '../common/Styles';
import BaseGridContainer from '../common/BaseGridContainer';
import InnerTabFilterSelectField from '../common/innerTab/InnerTabFilterSelectField';
import { strToBool } from '../common/CustomEnumDefs';
import TableFieldMeta from '../../common/TableFieldMeta';
import BankAccountVerification from '../account/accountProfile/BankAccountVerification';
import InnerTabFilterGridItemTextField from '../common/innerTab/InnerTabFilterGridItemTextField';
import BankAccountStatus from './BankAccountStatus';
import NoteButton from '../common/notes/NoteButton';
import { createQueryRendererProgressV2 } from '../common/QueryRendererProgressV2';
import TablePaginator from '../common/TablePaginator';

const statusDefault = 'PEN';

function BankAccountList(props) {
  const {
    filter,
    viewer,
    relay,
  } = props;
  filter.setRelay(relay);
  const classes = useCommonStyles();
  const columns = [
    TableFieldMeta.localId,
    TableFieldMeta.createdAt,
    TableFieldMeta.account(node => node.account),
    TableFieldMeta.field('holderName', 'Holder Name'),
    TableFieldMeta.field('bsbNumber', process.env.DEPLOYMENT_CODE === 'AU' ? 'BSB Number' : 'Sort Code'),
    TableFieldMeta.field('accountNumber', 'Account Number'),
    TableFieldMeta.field(node => <BankAccountStatus bankAccount={node} />,
      'Status', { align: 'center' }),
    TableFieldMeta.field(node => node.bankaccountdocumentSet.count, 'Docs..'),
    TableFieldMeta.field(node => (
      <BankAccountVerification bankAccount={node} />
    ), '', { align: 'center' }),
    TableFieldMeta.field(node => <NoteButton notesOwner={node} internalDrawer />),
  ];

  return (
    <div className={classes.innerTabRoot}>
      <BaseGridContainer>

        <InnerTabFilterGridItemTextField
          id="search-filter"
          label="Search"
          defaultValue={filter.params.search}
          onChange={e => filter.updateVar('search', e.target.value)}
          helperText="BSB/Acc Number/Holder Name"
        />

        <InnerTabFilterSelectField
          enumType="BankAccountStatus"
          label="Status"
          value={filter.params.status}
          helperText="Verified/Pending/Disabled"
          onChange={e => filter.updateVar('status', e.target.value)}
        />

        <InnerTabFilterSelectField
          enumType="DocsUploadedStatus"
          label="Docs"
          value={filter.params.docsUploaded}
          helperText="Uploaded/Not"
          onChange={e => filter.updateVar('docsUploaded', strToBool(e.target.value))}
        />

        <Grid item xs={12}>
          <TablePaginator
            filter={filter}
            columns={columns}
            getDataNode={() => (viewer ? viewer.bankAccounts : null)}
          />
        </Grid>

      </BaseGridContainer>
    </div>
  );
}

const query = graphql`
  query BankAccountListQuery (
    $first: Int
    $offset: Int
    $status: String
    $docsUploaded: Boolean
    $search: String
  ) {
    viewer {
      ...BankAccountList_viewer
      @arguments(
        first: $first
        offset: $offset
        status: $status
        docsUploaded: $docsUploaded
        search: $search
      ) 
    }
  }
`;

const container = createRefetchContainer(
  BankAccountList,
  {
    viewer: graphql`
       fragment BankAccountList_viewer on Query
       @argumentDefinitions(
         first: {type: Int, defaultValue: 10}
         offset: {type: Int}
         status: {type: String}
         docsUploaded: {type: Boolean}
         search: {type: String}
       ){
         bankAccounts(
          first: $first
           offset: $offset
           status: $status
           docsUploaded: $docsUploaded
           search: $search
           orderBy: "-id"
         ) {
           count
           edges {
             node {
               id
               account {
                 id
                 name
               }
               
               notes {
                 id
               }
               
               createdAt
               bsbNumber
               accountNumber
               holderName
               status
               dividendPayout
               
               bankaccountdocumentSet {
                 count
                 edges {
                   node {
                     id
                     fileUrl
                     fileName
                   }
                 }
               }
             }
           }
         }
       }
    `,
  },
  query,
);

export default withRouter(createQueryRendererProgressV2(
  query,
  container,
  {},
  () => ({
    status: statusDefault,
    docsUploaded: null,
    search: null,
  }),
  { debounceFields: ['search'] },
));
