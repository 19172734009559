import React from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { graphql } from 'react-relay';
import BankAccountVerification from './BankAccountVerification';
import AddBankAccountFrom from './au/AddBankAccountForm';
import BankAccountStatus from '../../globals/BankAccountStatus';
import DataTableWidget from '../../common/DataTableWidget';
import TableFieldMeta from '../../../common/TableFieldMeta';

export default function BankAccounts(props) {
  const {
    account,
  } = props;

  const bankAccounts = account.bankAccounts.edges;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <AddBankAccountFrom
          account={account}
          onClose={() => setDrawerOpen(false)}
        />
      </Drawer>

      <DataTableWidget
        title="Bank Accounts"
        rightTopComp={(
          <IconButton
            color="primary"
            aria-label="delete"
            onClick={() => setDrawerOpen(true)}
          >
            <Add />
          </IconButton>
        )}
        columnMeta={[
          TableFieldMeta.localId,
          TableFieldMeta.field('holderName', 'Holder Name'),
          TableFieldMeta.field('bsbNumber', process.env.DEPLOYMENT_CODE === 'AU' ? 'BSB Number' : 'Sort Code'),
          TableFieldMeta.field('accountNumber', 'Acc No.  '),
          TableFieldMeta.fieldCell('status', '', row => <BankAccountStatus bankAccount={row.value} />),
          TableFieldMeta.fieldCell('verify', '',
            row => <BankAccountVerification bankAccount={row.value} />),
        ]}
        dataNodes={bankAccounts.map(x => x.node)}
      />

    </React.Fragment>
  );
}

graphql`
  fragment BankAccounts_account on AccountNode {

    bankAccounts {
      edges {
        node {
          id
          holderName
          bsbNumber
          accountNumber
          status
          dividendPayout
        }
      }
    }

  }
`;
