import React from 'react';
import axios from 'axios';
import { MVA_AUTH_TOKEN_TAG } from '../../../common/constants';
import TableButton from './TableButton';
import { DownloadButton, ExportButton } from './BaseButton';
import downloadFileContent from '../../../common/download';

// Use this component for file downloads that require JWT
function StatelessDownloadButton(props) {
  const { downloadUrl, buttonType, ...otherProps } = props;

  const onClickPromise = () => {
    const config = {
      headers: {
        Authorization: `JWT ${localStorage.getItem(MVA_AUTH_TOKEN_TAG)}`,
      },
      responseType: 'arraybuffer',
    };
    return axios.get(downloadUrl, config)
      .then((response) => {
        const dispositionHeader = response.headers['content-disposition'];
        const filename = dispositionHeader ? dispositionHeader.split('filename=')[1] : `file_${Date.now()}`;
        downloadFileContent(
          response.data,
          response.headers.content_type,
          filename,
        );
      });
  };

  if (buttonType === 'export') {
    return (
      <ExportButton
        onClickPromise={onClickPromise}
        {...otherProps}
      />
    );
  }

  if (buttonType === 'download') {
    return (
      <DownloadButton
        onClickPromise={onClickPromise}
        {...otherProps}
      />
    );
  }

  return (
    <TableButton
      onClickPromise={onClickPromise}
      color="secondary"
      {...otherProps}
    />
  );
}

export default StatelessDownloadButton;
