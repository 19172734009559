import React from 'react';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Typography from '@material-ui/core/Typography';
import DateTime from '../../common/DateTimeMoment';


function AttachmentList(props) {
  const { attachments } = props;
  return (
    <Grid container spacing={1}>
      {/*<Grid item xs={12}>*/}
      {/*  {note.text}*/}
      {/*</Grid>*/}
      {
        attachments.map(attachment => (
          <>
            <Grid item xs={12}>
              <Chip
                size="small"
                icon={<AttachmentIcon />}
                label={attachment.node.fileName}
                clickable
                component="a"
                href={attachment.node.fileUrl}
                color="primary"
                target="_blank"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">
                {attachment.node.uploadedBy.fullName}
                &nbsp;&bull;&nbsp;
                <DateTime>
                  {attachment.node.updatedAt}
                </DateTime>
              </Typography>
            </Grid>

          </>
        ))
      }

    </Grid>
  );
}

export default AttachmentList;
